import { useState, useEffect } from "react";
import { Container } from "@mui/material";
import OneBook from "../Book/OneBook";
import { bookInfos } from "../../Type/props";
import Circular from "../../Global/Circular";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../Book/firebase";
import { baseURL2 } from "../Register/Login";
import axios from "axios";
import OneCourse from "./OneCourse";
const AllCourse = () => {
  const [course, setCourse] = useState<bookInfos[]>([] as bookInfos[]);
  const [loading, setLoading] = useState<boolean>(true);
  useEffect(() => {
    const getCourse = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/course`
        );
       if(response.data.success){
        setCourse(response.data.data);
        setLoading(false);
       }else{
         console.log(response.data.message);
       }
      } catch (error) {
        console.log(error);
      }
    };
    getCourse();
  }, []);
  return (
    <>
      {!loading && (
        <Container sx={{ my: 2, display: "flex", flexWrap: "wrap", gap: 5 }}>
          {course.map((course, index) => (
            <OneCourse
              id={course.id}
              title={course.title}
              image={
                (course.image as string).includes("http")
                  ? (course.image as string)
                  : `${process.env.REACT_APP_API_URL}/courseImage/${course.image}`
              }
            />
          ))}
        </Container>
      )}
      {loading && <Circular />}
    </>
  );
};

export default AllCourse;
