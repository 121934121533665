import * as React from "react";
import { styled, Theme, CSSObject } from "@mui/material/styles";
import { useTheme, useMediaQuery } from "@mui/material";
import MuiDrawer from "@mui/material/Drawer";
import { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import AdsClickIcon from "@mui/icons-material/AdsClick";
import AutoStoriesIcon from "@mui/icons-material/AutoStories";
import logo from "../../Assets/logo.jpg";
import { useNavigate } from "react-router-dom";
import { SideBarProps } from "../../Type/props";
import { Add, School } from "@mui/icons-material";
const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));
export default function SideBar(props: SideBarProps) {
  const { selected } = props;
  const [open, setOpen] = React.useState(true);
  const items = [
    { title: "الكتب", to: "allBook", icon: <AutoStoriesIcon /> },
    { title: "اضافة كتاب", to: "addBook", icon: <AdsClickIcon /> },
    //course and add course
    { title: "الدورات", to: "allCourse", icon: <School /> },
    { title: "اضافة دورة", to: "addCourse", icon: <Add /> },
  ];
  const navigate = useNavigate();
  const theme = useTheme();
  const isMatchedPhone = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Drawer
      anchor="right"
      // shor rtl
      dir="rtl"
      variant="permanent"
      open={isMatchedPhone ? false : true}
    >
      <DrawerHeader sx={{ display: "flex", justifyContent: "center", mt: 1 }}>
        <img
          src={logo}
          alt="logo"
          style={{ width: isMatchedPhone ? "50px" : "90px", cursor: "pointer" }}
          onClick={() => navigate("/")}
        />
      </DrawerHeader>
      <Divider textAlign="left">Manage</Divider>
      <List>
        {items.map((item, index) => (
          <ListItem key={index} disablePadding sx={{ display: "block" }}>
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
                my: 1,

                color: selected == item.to ? "white" : "primary.dark",
                bgcolor: selected == item.to ? "primary.main" : null,
                "&:hover": { bgcolor: "primary.main" },
              }}
              onClick={() => navigate(`/Dashboard/${item.to}`)}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                  color: selected == item.to ? "white" : "primary.dark",
                }}
              >
                {item.icon}
              </ListItemIcon>
              <ListItemText
                primary={item.title}
                sx={{ opacity: open ? 1 : 0 }}
              />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Drawer>
  );
}
