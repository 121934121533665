import { useState, useRef, useEffect } from "react";
import {
  Button,
  Container,
  TextField,
  Box,
  useTheme,
  useMediaQuery,
  IconButton,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Paper,
  Grid,
  Typography,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";

import Plan from "../Book/Plan";
import { PropsAlert, Type, bookInfos, courseInfos } from "../../Type/props";
import AddBoxIcon from "@mui/icons-material/AddBox";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import FileFree from "../Book/FileFree";
import FilePaid from "../Book/FilePaid";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import Alerte from "../../Global/Alerte";
import { useNavigate, useParams } from "react-router-dom";
import { db } from "../Book/firebase";
import { addDoc, collection, setDoc } from "firebase/firestore";
import { baseURL2 } from "../Register/Login";
import Circular from "../../Global/Circular";
import ArticleList from "../Article/articleList";
const AddCourse = () => {
  const { ref } = useParams();
  useEffect(() => {
    const fetchCourse = async () => {
      const course = await axios
        .get(`${process.env.REACT_APP_API_URL}/course/${ref}`)
        .then((res) => res.data?.data)
        .catch((err) => {
          console.log(err);
          return null;
        });
      if (!course) {
        return;
      }
      setCourseInfo(course);
      setImage(`${process.env.REACT_APP_API_URL}/courseImage/${course.image}`);
      setIsAdded(true);
    };
    if (ref) {
      fetchCourse();
    }
  }, []);
  const ImageRef = useRef(null);
  const navigator = useNavigate();
  const [image, setImage] = useState<string>("");
  const [openDelete, setOpenDelete] = useState<boolean>(false);
  const [isAdded, setIsAdded] = useState<boolean>(false);
  const [courseInfo, setCourseInfo] = useState<courseInfos>({} as courseInfos);
  const [alerting, setAlerting] = useState<PropsAlert>({} as PropsAlert);
  const [loading, setLoading] = useState<boolean>(false);

  const validateInputs = () => {
    const keyofCourseInfo: {
      key: keyof courseInfos;
      message: string;
      type: "text" | "number" | "boolean" | "file";
    }[] = [
      { key: "title", message: "اضف عنوان الدورة", type: "text" },
      { key: "description", message: "اضف وصف الدورة", type: "text" },
      { key: "price", message: "ادخل ثمن الدورة", type: "number" },
      { key: "index", message: "ادخل ترتيب الدورة", type: "number" },
      { key: "free", message: "اختر نوع الدورة", type: "boolean" },
      {
        key: "image",
        message: "اضف صورة الدورة",
        type: "file",
      },
    ];

    //validate
    for (let i = 0; i < keyofCourseInfo.length; i++) {
      const { key, message, type } = keyofCourseInfo[i];
      if (type === "text" && !courseInfo[key]) {
        setAlerting({ open: true, alerting: { type: "error", text: message } });
        return false;
      }
      if (type === "number" && Number.isNaN(courseInfo[key])) {
        setAlerting({ open: true, alerting: { type: "error", text: message } });
        return false;
      }
      if (type === "file" && !courseInfo[key]) {
        setAlerting({ open: true, alerting: { type: "error", text: message } });
        return false;
      }
      if (type === "boolean" && courseInfo[key] === undefined) {
        setAlerting({ open: true, alerting: { type: "error", text: message } });
        return false;
      }
    }

    return true;
  };
  const handleAddArticle = async () => {
    navigator(`/Dashboard/addArticle/${ref}`);
  };
  const handleDelete = async () => {
    setLoading(true);
    const isDeleted = await axios
      .delete(`${process.env.REACT_APP_API_URL}/course/${ref}`)
      .then((res) => res.data.success)
      .catch((err) => {
        console.log(err);
        return false;
      });
    if (!isDeleted) {
      setLoading(false);
      setAlerting({
        open: true,
        alerting: { type: "error", text: "An error occurred." },
      });
      return;
    }
    setLoading(false);
    navigator("/Dashboard/allCourse");
    setAlerting({ open: true, alerting: { type: "success", text: "" } });
  };
  const handleAdd = async (e: any) => {
    e.preventDefault();
    await setAlerting({} as PropsAlert);
    setLoading(true);
    if (!validateInputs()) {
      setLoading(false);

      return;
    }
    const formData = new FormData();
    const { image, ...rest } = courseInfo;
    formData.append("image", courseInfo.image as File);
    formData.append("course", JSON.stringify(rest));

    var isUpload = await axios
      .post(`${process.env.REACT_APP_API_URL}/course`, formData)
      .then(async (res) => {
        const { success, message } = res.data;
        console.log(success, message);
        return !!success;
      })
      .catch((err) => {
        console.log(err);
        return false;
      });

    if (!isUpload) {
      setLoading(false);
      setAlerting({
        open: true,
        alerting: { type: "error", text: "An error occurred." },
      });
      return;
    }

    setLoading(false);

    navigator("/Dashboard/course");
    setAlerting({ open: true, alerting: { type: "success", text: "" } });
  };
  const handleSave = async () => {
    setLoading(true);
    if (!validateInputs()) {
      setLoading(false);

      return;
    }
    const formData = new FormData();
    const { image, ...rest } = courseInfo;
    if (courseInfo.image instanceof File) {
      formData.append("image", courseInfo.image as File);
    }
    formData.append("course", JSON.stringify(rest));

    var isUpload = await axios
      .put(`${process.env.REACT_APP_API_URL}/course/${ref}`, formData)
      .then(async (res) => {
        const { success, message } = res.data;
        console.log(success, message);
        return !!success;
      })
      .catch((err) => {
        console.log(err);
        return false;
      });

    if (!isUpload) {
      setLoading(false);
      setAlerting({
        open: true,
        alerting: { type: "error", text: "An error occurred." },
      });
      return;
    }

    setLoading(false);

    navigator("/Dashboard/allCourse");
    setAlerting({ open: true, alerting: { type: "success", text: "" } });
  };
  const onLoadImage = (event: any) => {
    const { files } = event.target;
    if (files && files[0]) {
      const blob = URL.createObjectURL(files[0]);
      console.log(files[0]);

      const uniqueFilename = `${uuidv4()}_${Date.now()}_${files[0].name}`;
      const modifiedFile = new File([files[0]], uniqueFilename, {
        type: files[0].type,
      });
      console.log(modifiedFile);

      setImage(blob);
      setCourseInfo({ ...courseInfo, image: modifiedFile });
    }
    event.target.value = "";
  };
  const theme = useTheme();
  const isMatchedPhone = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <>
      {!isAdded && !ref && (
        <Container
          sx={{
            my: 3,
            height: "70vh",
            display: "flex",
            flexDirection: "column",
            rowGap: 5,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Plan
            type={courseInfo.free ? "free" : "paid"}
            setType={() => {}}
            onSetType={(type: Type) => {
              console.log("type", type);

              setCourseInfo({
                ...courseInfo,
                free: type === "free",
                price: type === "free" ? 0 : courseInfo.price,
              });
            }}
          />
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddBoxIcon />}
            size="large"
            onClick={() => setIsAdded(true)}
          >
            اضف الدورة
          </Button>
        </Container>
      )}
      {isAdded && (
        <>
          <Container
            dir="rtl"
            component="form"
            onSubmit={handleAdd}
            sx={{ my: 5, display: "flex", flexWrap: "wrap", gap: 3 }}
          >
            <Box
              // show rtl

              sx={{ flex: 1, display: "grid", gap: 2 }}
            >
              <TextField
                type="text"
                label="اذخل عنوان الدورة"
                variant="outlined"
                color="primary"
                value={courseInfo.title || ""}
                onChange={(e) =>
                  setCourseInfo({ ...courseInfo, title: e.target.value })
                }
              />

              {!courseInfo.free ? (
                <TextField
                  type="number"
                  label="ادخل ثمن الدورة"
                  variant="outlined"
                  color="primary"
                  value={courseInfo.price < 0 ? 0 : courseInfo.price}
                  onChange={(e) =>
                    setCourseInfo({
                      ...courseInfo,
                      price: Number(e.target.value),
                    })
                  }
                />
              ) : null}
              <TextField
                type="number"
                label="ادخل ترتيب الدورة"
                variant="outlined"
                color="primary"
                value={courseInfo.index < 0 ? 0 : courseInfo.index}
                onChange={(e) =>
                  setCourseInfo({
                    ...courseInfo,
                    index: Number(e.target.value),
                  })
                }
              />

              <TextField
                type="text"
                label="اضف وصف الدورة"
                variant="outlined"
                color="primary"
                value={courseInfo.description || ""}
                onChange={(e) =>
                  setCourseInfo({ ...courseInfo, description: e.target.value })
                }
                multiline
                rows={4}
                fullWidth
              />
            </Box>
            <Box
              sx={{
                flex: 1,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  position: "relative",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "220px",
                  aspectRatio: 1 / 1.41,
                  bgcolor: "background.paper",
                  borderRadius: "10px",
                  p: 1,
                  border: `solid 1.5px ${theme.palette.primary.main}`,
                }}
              >
                {!image ? (
                  <Button
                    variant="contained"
                    component="label"
                    size={isMatchedPhone ? "medium" : "large"}
                    startIcon={<CloudUploadIcon />}
                  >
                    اضف صورة
                    <input
                      ref={ImageRef}
                      type="file"
                      name="image"
                      style={{ display: "none" }}
                      accept="image/*"
                      onChange={onLoadImage}
                    />
                  </Button>
                ) : (
                  <>
                    <IconButton
                      size="small"
                      sx={{
                        position: "absolute",
                        top: "-15px",
                        right: "-15px",
                        bgcolor: "error.main",
                        color: "white",
                        "&:hover": { bgcolor: "error.main" },
                      }}
                      onClick={() => setImage("")}
                    >
                      <HighlightOffIcon />
                    </IconButton>
                    <img
                      src={image}
                      alt="custom card"
                      loading="lazy"
                      style={{
                        width: "100%",
                        height: "100%",
                        borderRadius: "10px",
                      }}
                    />
                  </>
                )}
              </Box>
            </Box>

            {!ref && (
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  my: 3,
                }}
              >
                <Button
                  type="submit"
                  variant="contained"
                  size="large"
                  startIcon={<AddBoxIcon />}
                >
                  اضف الدورة
                </Button>
              </Box>
            )}
          </Container>
        </>
      )}
      {ref && (
        <Box sx={{ display: "flex", justifyContent: "space-evenly" }}>
          <Button
            variant="outlined"
            color="error"
            startIcon={<DeleteIcon />}
            sx={{ color: "error.main" }}
            onClick={() => setOpenDelete(true)}
          >
            حذف
          </Button>
          <Button
            variant="contained"
            color="primary"
            startIcon={<SaveIcon />}
            onClick={handleSave}
          >
            تعديل
          </Button>
        </Box>
      )}
      {/* 
      section of list of articles 
       */}
      <Container maxWidth="lg" sx={{ my: 5 }}>
        <Paper elevation={3} sx={{ p: 4, borderRadius: 4 }}>
          <Grid container spacing={3} alignItems="center" mb={4}>
            <Grid item>
              <Button
                variant="outlined"
                color="primary"
                onClick={handleAddArticle}
              >
                اضف مقال جديد
              </Button>
            </Grid>
            <Grid item xs>
              <Typography dir="rtl" variant="h5" component="h2">
                المقالات
              </Typography>
            </Grid>
          </Grid>
          {ref && <ArticleList courseId={ref!} />}{" "}
        </Paper>
      </Container>
      <Alerte open={alerting.open} alerting={alerting.alerting} />
      {loading && <Circular />}

      {ref && (
        <Dialog open={openDelete} onClose={() => setOpenDelete(false)}>
          <DialogContent>
            <DialogContentText>هل انت متأكد من الحذف</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenDelete(false)}>لا</Button>
            <Button onClick={handleDelete} autoFocus>
              نعم
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default AddCourse;
