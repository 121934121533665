import { Box, Container, TextField, Button } from "@mui/material";
import { ArticleInfos, PropsAlert } from "../../Type/props";
import React, { useEffect, useMemo, useState } from "react";
import TextEditor from "./TextEditor";
import AddBoxIcon from "@mui/icons-material/AddBox";
import { useNavigate, useParams } from "react-router-dom";
import Alerte from "../../Global/Alerte";
import Circular from "../../Global/Circular";
import axios from "axios";

export default function AddArticle() {
  const { Selected, ref } = useParams();
  const [articleInfo, setArticleInfo] = React.useState<ArticleInfos>({
    courseId: ref,
  } as ArticleInfos);
  useEffect(() => {
    const fetchArticle = async () => {
      setLoading(true);

      const article = await axios
        .get(`${process.env.REACT_APP_API_URL}/course/article/${ref}`)
        .then((res) => res.data?.data)
        .catch((err) => {
          console.log(err);
          return null;
        });
      setLoading(false);

      if (!article) {
        return;
      }
      setEditorKey(3);
      setClear(true);
      setArticleInfo(article);
    };
    if (Selected === "editArticle") {
      fetchArticle();
    }
  }, []);
  const [alerting, setAlerting] = useState<PropsAlert>({} as PropsAlert);
  const [loading, setLoading] = useState<boolean>(false);
  const navigator = useNavigate();

  const [editorKey, setEditorKey] = useState(0);
  const validateInputs = () => {
    const keyofCourseInfo: {
      key: keyof ArticleInfos;
      message: string;
      type: "text" | "number" | "boolean" | "file";
    }[] = [
      { key: "title", message: "اضف عنوان الدورة", type: "text" },
      { key: "description", message: "اضف وصف الدورة", type: "text" },
      { key: "index", message: "ادخل ترتيب الدورة", type: "number" },
      { key: "free", message: "اختر نوع الدورة", type: "boolean" },
    ];

    //validate
    for (let i = 0; i < keyofCourseInfo.length; i++) {
      const { key, message, type } = keyofCourseInfo[i];
      if (type === "text" && !articleInfo[key]) {
        setAlerting({ open: true, alerting: { type: "error", text: message } });
        return false;
      }
      if (type === "number" && Number.isNaN(articleInfo[key])) {
        setAlerting({ open: true, alerting: { type: "error", text: message } });
        return false;
      }
      if (type === "file" && !articleInfo[key]) {
        setAlerting({ open: true, alerting: { type: "error", text: message } });
        return false;
      }
      if (type === "boolean" && articleInfo[key] === undefined) {
        setAlerting({ open: true, alerting: { type: "error", text: message } });
        return false;
      }
    }

    return true;
  };
  const [clear, setClear] = useState(false);
  const handleAddArticle = async (e: any) => {
    e.preventDefault();
    await setAlerting({} as PropsAlert);
    setLoading(true);
    if (!validateInputs()) {
      setLoading(false);

      return;
    }
    const formData = new FormData();
    const { image, ...rest } = articleInfo;
    if (image) {
      formData.append("image", image as File);
    }
    formData.append("article", JSON.stringify(rest));

    var isUpload = await axios
      .post(`${process.env.REACT_APP_API_URL}/course/article`, formData)
      .then(async (res) => {
        const { success, message } = res.data;
        console.log(success, message);
        return !!success;
      })
      .catch((err) => {
        console.log(err);
        return false;
      });

    if (!isUpload) {
      setLoading(false);
      setAlerting({
        open: true,
        alerting: { type: "error", text: "حذف خطأ" },
      });
      return;
    }

    setLoading(false);

    navigator("/Dashboard/addCourse/" + ref);
    setAlerting({ open: true, alerting: { type: "success", text: "" } });
  };
  const handleSaveArticle = async (e: any) => {
    e.preventDefault();
    await setAlerting({} as PropsAlert);
    setLoading(true);
    if (!validateInputs()) {
      setLoading(false);

      return;
    }
    const formData = new FormData();
    const { image, ...rest } = articleInfo;
    if (image) {
      formData.append("image", image as File);
    }
    formData.append("article", JSON.stringify(rest));

    var isUpload = await axios
      .put(`${process.env.REACT_APP_API_URL}/course/article/` + ref, formData)
      .then(async (res) => {
        const { success, message } = res.data;
        console.log(success, message);
        return !!success;
      })
      .catch((err) => {
        console.log(err);
        return false;
      });

    if (!isUpload) {
      setLoading(false);
      setAlerting({
        open: true,
        alerting: { type: "error", text: "حذف خطأ" },
      });
      return;
    }

    setLoading(false);

    navigator("/Dashboard/addCourse/" + articleInfo.courseId);
    setAlerting({ open: true, alerting: { type: "success", text: "" } });
  };
  const renderedTextEditor = useMemo(
    () => (
      <TextEditor
        key={editorKey + 1}
        disabled={false}
        setData={(value) =>
          setArticleInfo((pv) => ({ ...pv, description: value }))
        }
        initialData={articleInfo.description ?? ""}
      />
    ),
    [clear]
  );
  return (
    <div>
      <Container
        sx={{
          my: 3,
          height: "70vh",
          direction: "rtl",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          rowGap: 5,
          justifyContent: "start",
          alignItems: "center",
        }}
      >
        <Box
          // show rtl

          sx={{
            display: "flex",
            gap: 2,
            width: "100%",
          }}
        >
          <TextField
            fullWidth
            type="text"
            label="اذخل عنوان الدورة"
            variant="outlined"
            color="primary"
            value={articleInfo.title || ""}
            onChange={(e) =>
              setArticleInfo({ ...articleInfo, title: e.target.value })
            }
          />
          <TextField
            fullWidth
            type="number"
            label="ادخل ترتيب المقال"
            variant="outlined"
            color="primary"
            value={articleInfo.index < 0 ? 0 : articleInfo.index}
            onChange={(e) =>
              setArticleInfo({
                ...articleInfo,
                index: Number(e.target.value),
              })
            }
          />
          {/* 
            free or payment

            
          */}
          <TextField
            fullWidth
            select
            label="اختر نوع الدورة"
            variant="outlined"
            color="primary"
            value={articleInfo.free ? "free" : "paid"}
            onChange={(e) =>
              setArticleInfo({
                ...articleInfo,
                free: e.target.value === "free",
              })
            }
            SelectProps={{
              native: true,
            }}
          >
            <option value="free">مجانية</option>
            <option value="paid">مدفوعة</option>
          </TextField>
        </Box>

        <div className="w-full">{renderedTextEditor}</div>
        <Alerte open={alerting.open} alerting={alerting.alerting} />
        {loading && <Circular />}

        {Selected === "addArticle" && (
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              my: 3,
            }}
          >
            <Button
              type="submit"
              onClick={handleAddArticle}
              variant="contained"
              size="large"
              startIcon={<AddBoxIcon />}
            >
              اضف المقال
            </Button>
          </Box>
        )}
        {Selected === "editArticle" && (
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              my: 3,
            }}
          >
            <Button
              type="submit"
              onClick={handleSaveArticle}
              variant="contained"
              size="large"
              startIcon={<AddBoxIcon />}
            >
              حفظ المقال
            </Button>
          </Box>
        )}
      </Container>
    </div>
  );
}
