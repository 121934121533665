import React, { useRef } from "react";
import JoditEditor, { Jodit } from "jodit-react";
import { DeepPartial, IJodit, IUploaderAnswer } from "jodit/types/types";
import { Config } from "jodit/types/config";
import { an } from "framer-motion/dist/types.d-6pKw1mTI";

interface TextEditorProps {
  setData: (data: string) => void;
  initialData: string;
  disabled: boolean;
}

export default function TextEditor({
  setData,
  initialData,
  disabled,
}: TextEditorProps) {
  const editorRef = useRef<IJodit | null>(null);
  const editorKey = "myEditor"; // Unique key for reference

  const config: DeepPartial<Config> = {
    placeholder: "",
    direction: "rtl",
    readonly: disabled,
    uploader: {
      url: process.env.REACT_APP_API_URL + "/course/upload",
      format: "json",
      isSuccess: function (resp: any) {
        return !resp.error;
      },
      getMessage: function (resp: any) {
        return resp.msg.join !== undefined ? resp.msg.join(" ") : resp.msg;
      },
      headers: { "X-Requested-With": "XMLHttpRequest" },
      process: (resp: any) => ({
        files: [resp.url], // Return the uploaded image URL
      }),
      defaultHandlerSuccess: (resp: any) => {
        console.log(resp);
        const keys = Object.keys(Jodit.instances);

        const editorInstance = Jodit.instances[keys[0]];
        console.log(resp);
        console.log(editorInstance);
        console.log(process.env.REACT_APP_API_URL + resp.files[0]);
        
        if (resp.files && editorInstance) {
          editorInstance.s.insertImage(
            process.env.REACT_APP_API_URL + resp.files[0]
          );
        }
      },
      insertImageAsBase64URI: false,

      filesVariableName: () => "images",
    },
    filebrowser: {
      ajax: { url: "/course" },
    },
  };

  return (
    <JoditEditor
      config={config as any}
      ref={editorRef}
      key={editorKey}
      value={initialData}
      onChange={(newContent) => setData(newContent)}
    />
  );
}
