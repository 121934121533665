import { useState, useEffect } from "react";
import { Box, Container } from "@mui/material";
import SideBar from "./SideBar";
import Appbar from "./TopBar";
import { useNavigate, useParams } from "react-router-dom";
import Circular from "../../Global/Circular";
import AddBook from "../Book/AddBook";
import AllBooks from "../Book/AllBooks";
import AllCourse from "../Course/AllCourse";
import AddCourse from "../Course/AddCourse";
import AddArticle from "../Article/AddArticle";
const Dashboard = () => {
  const { Selected, addArticle } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(true);
  const allSections = [
    "allBook",
    "addBook",
    "allCourse",
    "addCourse",
    "addArticle",
    "editArticle",
  ];
  useEffect(() => {
    if (Selected) {
      if (!allSections.includes(Selected)) {
        navigate("/404");
      } else {
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      }
    }
  }, []);
  return (
    <>
      {!loading && (
        <Box sx={{ display: "flex" }}>
          <Box sx={{ width: "100%" }}>
            <Appbar />
            {Selected === "allBook" && <AllBooks />}
            {Selected === "addBook" && <AddBook />}
            {Selected === "allCourse" && <AllCourse />}
            {Selected === "addArticle" && <AddArticle />}
            {Selected === "editArticle" && <AddArticle />}
            {Selected === "addCourse" && <AddCourse />}
          </Box>
          <SideBar selected={Selected || "AllBook"} />
        </Box>
      )}
      {loading && <Circular />}
    </>
  );
};

export default Dashboard;
