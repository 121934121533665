import React, { useState } from "react";
import {
  List,
  ListItem,
  ListItemText,
  IconButton,
  Button,
  Typography,
  Divider,
  Box,
  Link,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import { Edit as EditIcon, Delete as DeleteIcon } from "@mui/icons-material";
import { ArticleInfos } from "../../Type/props";
import { motion } from "framer-motion";
import axios from "axios";
import Circular from "../../Global/Circular";

const MotionListItem = motion(ListItem);

export default function ArticleList({ courseId }: { courseId: string }) {
  const [articles, setArticles] = React.useState<ArticleInfos[]>([]);
  const fetchListArticle = async () => {
    const articles = await axios
      .get(`${process.env.REACT_APP_API_URL}/course/articles/${courseId}`)
      .then((res) => res.data?.data)
      .catch((err) => {
        console.log(err);
        return null;
      });
    setArticles(articles);
  };
  const [openDelete, setOpenDelete] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const [deletedId, setdeletedId] = useState<string | undefined>();
  React.useEffect(() => {
    fetchListArticle();
  }, []);
  const deleteArticle = (id: string) => {
    setdeletedId(id);
    setOpenDelete(true);
  };

  const handleDelete = async () => {
    setOpenDelete(false);
    setLoading(true);
    const isDeleted = await axios
      .delete(`${process.env.REACT_APP_API_URL}/course/article/${deletedId}`)
      .then((res) => res.data.success)
      .catch((err) => {
        console.log(err);
        return false;
      });
    if (!isDeleted) {
      setLoading(false);

      return;
    }
    await fetchListArticle();
    setLoading(false);
  };
  return (
    <List
      style={{
        margin: 3,
      }}
    >
      {articles.map((article, index) => (
        <React.Fragment key={article.id}>
          <MotionListItem
            secondaryAction={
              <Box>
                <Link href={`/Dashboard/editArticle/${article.id}`}>
                  <IconButton edge="end" aria-label="edit" sx={{ mr: 1 }}>
                    <EditIcon />
                  </IconButton>
                </Link>
                <IconButton
                  edge="end"
                  aria-label="delete"
                  onClick={() => deleteArticle(article.id)}
                >
                  <DeleteIcon />
                </IconButton>
              </Box>
            }
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: index * 0.1, duration: 0.5 }}
          >
            <div>
              <Typography variant="h4" component="h3">
                #{article.index}
              </Typography>
            </div>
            <ListItemText
              sx={{
                marginLeft: 4,
              }}
              primary={
                <Typography variant="h6" component="h3">
                  {article.title}
                </Typography>
              }
              secondary={
                <Typography variant="body2" color="text.secondary">
                  {article.description.slice(0, 100)}...
                </Typography>
              }
            />
          </MotionListItem>
          {index < articles.length - 1 && <Divider component="li" />}
        </React.Fragment>
      ))}
      {deletedId && (
        <Dialog
          open={openDelete}
          onClose={() => {
            setOpenDelete(false);
            setdeletedId(undefined);
          }}
        >
          <DialogContent>
            <DialogContentText>هل انت متأكد من الحذف</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setOpenDelete(false);
                setdeletedId(undefined);
              }}
            >
              لا
            </Button>
            <Button onClick={handleDelete} autoFocus>
              نعم
            </Button>
          </DialogActions>
        </Dialog>
      )}
      {loading && <Circular />}
    </List>
  );
}
