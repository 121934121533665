import React, { useEffect, useState } from 'react'
import { Box, Button, InputAdornment, TextField, Typography, useTheme, useMediaQuery } from '@mui/material'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import LoginIcon from '@mui/icons-material/Login';
import login from '../../Assets/login.svg'
import { PropsAlert } from '../../Type/props';
import Alerte from '../../Global/Alerte';
import axios from 'axios';

import { useNavigate } from 'react-router-dom';

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
console.log(process.env.REACT_APP_API_URL);

export const baseURL2 = process.env.REACT_APP_API_URL2;

const Login = () => {
    const [Email, setEmail] = useState<string>('');
    const navigator = useNavigate();
    const [Password, setPassword] = useState<string>('');
    const [visibilityPassword, setVisibilityPassword] = useState<boolean>(true);
    const [alerting, setAlerting] = useState<PropsAlert>({} as PropsAlert)
    const [emptyEmail, setEmptyEmail] = useState<boolean>(false);
    const [emptyPassword, setEmptyPassword] = useState<boolean>(false);
    const [errorLogin, setErrorLogin] = useState<string>('');
    const handleSubmitLogin = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        // await setAlerting({} as PropsAlert)
        if (!Email) {
            setEmptyEmail(true);
        }
        if (!Password) {
            setEmptyPassword(true);
        }
        if (Email && Password) {
            axios.post(`${process.env.REACT_APP_API_URL}/login`, {
                Email,
                Password
            })
                .then(data => {
                    const { success } = data.data;
                    console.log(data.data);

                    if (success) {
                        setTimeout(() => {
                            console.log(data.data);

                            navigator("/Dashboard/allBook", { replace: true })

                            setAlerting({ open: true, alerting: { type: 'success', text: 'You are logged in.' } })
                        }, 1000);
                    }
                })
                .catch(err => {
                    setAlerting({ open: true, alerting: { type: 'error', text: err.response?.data?.message || 'An error occurred.' } })
                })

        }
    };
    useEffect(() => {
        if (Email) {
            setEmptyEmail(false);
        }
        if (Password) {
            setEmptyPassword(false);
        }
        setErrorLogin('')
    }, [Email, Password]);
    const theme = useTheme();
    const ismatchedPhone = useMediaQuery(theme.breakpoints.down('sm'));
    const ismatchedTablette = useMediaQuery(theme.breakpoints.down('md'));
    return (
        <>
            <Box sx={{ height: '100vh', display: 'flex' }}>
                <Box sx={{ display: { xs: 'none', lg: 'flex' } }}>
                    <img src={login} alt='login' width='auto' loading='lazy' />
                </Box>
                <Box sx={{ flex: 1, display: 'flex', flexDirection: "column", justifyContent: "center", alignItems: 'center' }}>
                    <Typography variant={ismatchedPhone ? 'h3' : 'h2'} fontWeight='bolder' mb={3}>
                        Login
                    </Typography>
                    <form id='login' onSubmit={handleSubmitLogin} style={{ width: ismatchedPhone ? '80%' : ismatchedTablette ? '70%' : '60%', display: 'grid', rowGap: '10px', marginBottom: '10px', }}>
                        <TextField
                            name='Email'
                            label='Email'
                            type="text"
                            variant="filled"
                            autoComplete="email"
                            value={Email}
                            onChange={(e) => { setEmail(e.target.value) }}
                            error={emptyEmail}
                        />
                        <TextField
                            name='Password'
                            label='Password'
                            type={visibilityPassword ? 'password' : 'text'}
                            variant="filled"
                            value={Password}
                            autoComplete="current-password"

                            onChange={(e) => { setPassword(e.target.value) }}
                            error={emptyPassword}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position='end'>
                                        {
                                            visibilityPassword ? <VisibilityOffOutlinedIcon onClick={() => setVisibilityPassword(false)} sx={{ color: 'black', cursor: 'pointer' }} />
                                                : <VisibilityOutlinedIcon onClick={() => setVisibilityPassword(true)} sx={{ color: 'black', cursor: 'pointer' }} />
                                        }
                                    </InputAdornment>
                                )
                            }}
                        />
                        {
                            errorLogin && <Typography variant='body2' sx={{ mr: 'auto', color: 'red' }}>
                                {errorLogin}
                            </Typography>
                        }
                    </form>
                    <Button
                        form='login'
                        type='submit'
                        variant='contained'
                        color='primary'
                        endIcon={<LoginIcon />}
                        sx={{ mt: 2, '&:hover': { bgcolor: 'primary.main' }, fontWeight: 'bold' }}>
                        Login
                    </Button>
                </Box>
            </Box>
            <Alerte open={alerting.open} alerting={alerting.alerting} />
        </>
    )
}

export default Login